html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Segoe UI", Arial, sans-serif;
  background: #fafafa;
}

:root {
    --header-color: #29394F;
    --active-icon-color: #48b0f7;
    --primary-color: #48b0f7;
}
.partner {
    --header-color: #263238;
    //--active-icon-color: #1C7C1D;
    //--primary-color: #177C1D;
}

#root, [data-reactroot] {
    height: 100%;
}

.App {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.error {
    color: #dc2127;
}

.row {
    margin-left: 0px;
    margin-right: 0px;
}

.row > [class*='col-'] {
    padding-left: 0px;
    padding-right: 8px;
}

.row > .col {
    padding-left: 0px;
    padding-right: 8px;
}

hr {
  background-color: #626262;
  margin: 10px 0;
}

.beamer_defaultBeamerSelector {
  display: none;
}

.beamer_icon.active {
    position: relative !important;
}

a {
  color: #3a8fc8;
  text-decoration: none;
}

a:hover {
  color: #48b0f7;
}

a.plain {
    color: #626262;
}

h6 {
    margin: 10px 0;
    font-size: 12px;
}

h2 {
    font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
    &.title {
        font-weight: 300;
    }
}

h3, h4, h5 {
    margin-bottom: 0px
}

.subtext {
    font-size: 12px;
    font-style: italic;
}

.gap-12 {
    gap: 12px;
}

.gap-6 {
    gap: 6px;
}

.gray {
    color: #626262;
}

.gray2 {
    color: #a0a0a0;
}

.hide {
    display: none !important;
}

.showprint {
    display: none;
}

.center {
    text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered-container {
    display: flex;
    align-items: center;
    height: 100%;
}

.content-container {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.header {
    width: 100%;
    height: 60px;
    position: absolute;
    background-color: var(--header-color);
    border-bottom: 0px;
    padding: 0 20px 0 10px;
    z-index: 1001;
    color: #fff;
    box-shadow:0 2px 4px rgba(0, 0, 0, 0.08);
}

.header-toolbar {
    height: 60px;
}

.pull-left {
  float: left;
  padding: 0;
}

.pull-right {
  float: right;
  padding: 0;
}

.pull-center {
    display: inline-block;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 0;
}

.header-item {
  min-width: 20px;
  height: 60px;
  display: table-cell;
  vertical-align: middle;
  padding-left: 18px;

  a {
    color: white;
    text-decoration: none;

    span {
        font-size: 14px;
    }
  }

  &.logo-left {
    padding-left: 8px;
  }

  .header-logo {
    img {
      vertical-align: middle;
      height: 46px;
      padding-right: 10px;
    }

    span {
      font-size: 23px;
      vertical-align: middle;
    }

    .header-name {
      color: #333;
      font-size: 30px;
    }
  }

}



.dropdown {
  padding-left: 5px;

  &.form {
    padding-left: 0px;
  }

  &.inline {
    display: inline-block;
    margin-right: 5px;
  }

  &.disabled {
    pointer-events: none;
    background-color: #e9ecef;
  }

  a {
    color: #626262;
    text-decoration: none;
  }

  :not(.form-group)>input {
    border: 1px solid #E5E4E2 !important;
    padding: 5px !important;
  }

    .dropdown-toggle.single {
        font-size: 13px;
    }

    .dropdown-menu {
      background: #fafafa;
      white-space: nowrap;
      min-width: 120px;
      width: fit-content;

      &.skinny {
        padding: 0px;
      }

      li {
        padding: 8px 15px 8px 15px;
        font-size: 13px;

        &.skinny {
            padding: 5px 15px;
        }

        &.dropdown-header {
            padding: 8px 5px;
        }
      }
    }

    .dropdown-menu.single {
      background: white;
      white-space: nowrap;
      min-width: 120px;
      padding: 7px;
      max-height: 300px;

      ul.list-unstyled {
        margin-bottom: 0px;
        overflow-y: auto;
        max-height: 240px;
      }

      li {
        padding: 7px 5px 7px 2px;
        font-size: 13px;

        &:hover {
            background: #f0f0f0;
            cursor: pointer;
        }

        p {
            margin: 0px;

            &.label {
                font-weight: normal;
            }
        }
      }

      .dropdown-item {
        padding: 8px 8px;
        color: #626262;
        font-size: 13px;

        &:hover {
            background: #f0f0f0;
            cursor: pointer;
        }

        &.active {
            background: #f0f0f0;
        }

        &.disabled {
          opacity: 0.4;
        }
      }
    }
}

.header-item-inner {
//   display: inner-block;

    img {
      vertical-align: middle;
      max-height: 50px;
    }
}

.header-item p {
  vertical-align: middle;
}

.btn-warning {
    background-color: #FAB033!important;
    color: #222222!important;
}

.btn-secondary {
  background: none;
  border: 0px;
  padding: 0px;

  &:focus, &:hover, &:active {
    background: none;
    border: 0px;
    box-shadow: none;
  }
}

.btn-lg {
  padding: 0px;
  font-size: 1rem;

  &:focus, &:hover, &:active {
    font-size: 1rem;
    background: none;
    box-shadow: none;
  }
}

.left-nav {
  background: #ffffff;
  width: 280px;
  height: 100%;
  padding: 60px 0 0 0;
  position: absolute;
  z-index: 11;
  margin-left: -208px;
  transition-duration: 500ms;
  transition-property: margin-left;
  color: #788195;
  border-right: 1px solid #E5E4E2;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }

  &.show {
    margin-left: 0px;
    transition-duration: 500ms;
    transition-property: margin-left;
  }

  .left-nav-bottom-banner {
    box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
    flex-grow: 0;
    position: relative;
    bottom: 0;
    padding: 13px;

    img {
        width: 100%;
    }
  }
}

@media (max-width: 576px) {
  .left-nav {
    margin-left: -280px;
    padding-top: 50px;
  }

  .left-nav-menu li {
    height: 50px !important;
  }
}

.left-nav-menu {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    flex-grow: 1;

    &::-webkit-scrollbar {
        display: none;
    }

  .nav-title {
    float: left;
    height: 40px;
    padding-left: 32px;
    display: table;
    width: 210px;

    span {
        display: table-cell;
        vertical-align: middle;
    }
  }

  .left-nav-menu-drop {
    .nav-title {
      width: 190px;
    }
  }

  .sub-menu {
    height: 0;
    display: none;

    &.show {
        height: initial;
        display: initial;
        transition-duration: 500ms;
        transition-property: height;
    }

    .nav-title {
        padding-left: 40px;

        span {
            font-size: 14px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 160px;
            display: block;
            padding-top: 10px;
        }
    }

    li {
        height: 46px;
        padding: 3px 0;
    }

    .icon-thumbnail i {
        font-size: 16px;
    }
  }

  .icon-thumbnail {
    float: right;
    height: 40px;
    width: 40px;
    margin-right: 15px;
    text-align: center;
    display: table;
    color: #788195;
    padding: 3px;

    &.active {
        background: var(--active-icon-color);
        color: white;
    }

    i {
        font-size: 20px;
        vertical-align: middle;
        display: table-cell;
    }

    span {
        color: white;
        font-weight: bold;
        font-size: 16px;
        margin: 5px;
        vertical-align: middle;
        display: table-cell;
        background: #788195;
    }
  }

  ul {
    list-style: none;
    width: 100%;
    padding: 13px 0;
  }

  li {
    list-style: none;
    height: 52px;
    padding: 6px 0;

    &.left-nav-menu-drop {
        cursor: pointer;
    }

    &:hover {
        background: #f2f2f2;
        color: #333333;
    }

    a {
        width: 100%;
        height: 100%;
        color: #788195;
        text-decoration: none;
    }

    a:hover {
        color: #333333;
    }

    .arrow {
        height: 40px;
        display: table-cell;
        vertical-align: middle;
        font-size: 16px;
        font-family: FontAwesome;
    }

    .arrow::before {
        content: "\f104";
    }

    &.show {
        .arrow::before {
            content: "\f107";
        }
    }
  }
}

.iframe_content_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 100px;
}

.iframe_content {
    display: block;
    flex-grow: 1;
    margin: 0px;
    padding: 0px;
    border: 0px;
    width: 100%;
    height: 100%;
}

.fa {
//     font-size: 14px;

    &.fa-small {
        font-size: 12px;
    }

    &.fa-large {
        font-size: 16px;
    }

    &.fa-c-primary {
        color: #48B0F7!important
    }

    &.fa-c-success {
        color: #10cfbd;
    }

    &.fa-c-dark-success {
        color: green;
        background: white;
        border-radius: 50%;
    }

    &.fa-c-info {
        color: #a0a0a0;
    }

    &.fa-c-yellow {
        color: yellow;
    }

    &.fa-c-danger {
        color: #f55753 !important;
    }

    &.fa-unselected {
        opacity: 0.3;
    }
}

.panel-body {
    padding: 20px 15px;
    min-width: 250px;
}

.content_wrapper {
    padding: 60px 0 0 72px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.container-fluid {
    &.full {
        height: 100%;
        display: flex;
        min-height: 0px;
        flex-direction: column;
        flex-flow: column;
    }
}

.calendar-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.screen-wrapper {
    padding: 15px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.dropdown-menu {
    .nav-wrapper {
        border-bottom: 1px solid #626262;
    }
}

.nav-wrapper {
    background: #fafafa;
    z-index: 2;
    max-width: 100%;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .nav {
        flex-wrap: nowrap;
    }

    &.alt {
        background-color: #29394F;

        .nav-link {
            color: white;
            font-size: 12px;
            padding: 10px 15px;

            &:hover {
                opacity: 1;
                background-color: #f2f2f230;
                color: white;
            }

            &.active {
                color: white;
                background-color: #f2f2f230;
            }

            &.skinny {
                padding: 10px !important;
            }
        }
    }

    .nav-link {
        color: #626262;
        text-transform: uppercase;
        font-size: 12px;
        padding: 15px 20px;
        transition: none;
        white-space: nowrap;

        &:hover, &:active, &:focus, &:focus:active {
            opacity: 1;
            background-color: #f2f2f2;
            color: #626262;
        }

        &.active {
            color: #626262;
            background-color: white;
            font-weight: bold;
            margin-bottom: -1px;
            border-left: 1px solid #E5E4E2;
            border-right: 1px solid #E5E4E2;
        }

        &.hide {
            display: none;
        }

        &:focus-visible {
            outline: none;
        }
    }

    .nav .open>a {
        border-color: #F2F2F2;
        background-color: #F2F2F2;
    }

    .dropdown {
        padding-left: 0px;
    }
}

.content-box {
    background: white;
    border: 1px solid #E5E4E2;
    margin-bottom: 20px;

    &.full {
        flex-grow: 1;
        margin-bottom: 0px;
    }

    .content-header {
        padding: 20px 20px 7px 20px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
    }

    .content-body {
        padding: 20px 20px 20px 20px;
        font-size: 13px;
        color: #626262;
        &.full {
            height: 100%
        }

        p {
            margin: 0 0 10px 0;

            &.thin {
                margin: 0px;
            }
        }

        &.invoice-container {
            padding: 50px;
            font-size: 16px;
            font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
            position: relative;

            .invoice-header {
                color: #262626;
                font-weight: 300;

                h2 {
                    font-weight: 300;
                }
            }

            .invoice-subdetails {
                font-size: 14px;
            }

            &.cancelled:after {
                content: url('../public/images/cancelled.png');
                position: absolute;
                left: 35%;
                top: 100px;
                opacity: 0.3
            }
        }

        &.tall {
            padding: 70px;
        }
    }

    .content-header + .content-body {
        padding-top: 0px;
    }
}

pre {
    padding: 20px;
    background-color: #e6e6e6;
    border-radius: 3px;

    code {
        background: #f0f0f0;
        color: #c64643;
    }
}

.alert {
    padding: 10px;
    border-width: 0px;
    font-size: 13px;

    p {
        margin: 0 !important;
    }

    &.skinny {
        margin-bottom: 0px;
    }
}

.alert-success {
    background-color: #10CFBD;
    color: white;
    font-weight: bold;
    border-color: #0a7c71;
}

.alert-info {
    background-color: #daeffd;
    color: #2b6a94;
    border-color: #2b6a94;
}

.alert-danger {
    background-color: #F55753;
    color: white;
    font-weight: bold;
}

.btn {
    font-size: 14px;
    color: #626262;
    margin-right: 3px;
    box-shadow: none;
    padding: 7px 13px;
    display: inline-block;

    &:disabled {
        opacity: 0.65;
    }
}

.btn:hover, .btn:focus, .btn:active, .btn:active:focus {
    color: #333;
    box-shadow: none;
}

.btn-nolink {
    font-size: 14px;
    color: #626262;
    margin-right: 3px;
    box-shadow: none;
    padding: 7px 17px;
    display: inline-block;
    border: 1px solid #f0f0f0;
    background-color: white;
    white-space: nowrap;
    cursor: default !important;
}

.btn-nolink:hover, .btn-nolink:focus, .btn-nolink:active, .btn-nolink:active:focus {
    color: #626262;
    box-shadow: none;
}

.btn-link {
    padding: 7px 13px;
    border: none;
    background-color: transparent;
    text-decoration: none;

    &.skinny {
        padding: 7px 7px;
    }
}

.btn-alink {
  color: #3a8fc8 !important;
  text-decoration: none;

  &.skinny {
    margin: 0px;
    padding: 0px 4px;
    border: 0px;
    margin-top: 0px;
  }
}

.btn-alink:hover {
  color: #48b0f7 !important;
}

.btn-header {
    font-size: 14px;
    color: white;
    box-shadow: none;
    padding: 0px;

    span {
        font-size: 15px;
    }
}

.btn-header:hover, .btn-header:focus, .btn-header:active, .btn-header:focus:active {
    color: white;
    box-shadow: none;
}

.btn-sm {
    font-size: 14px;
    padding: 5px 7px;

    .fa {
        font-size: 12px;
    }
}

.btn-success {
    color: #fff;
    background-color: #10cfbd;
    border-color: #10cfbd;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success:active:focus {
    color: #fff;
    background-color: #40d9ca;
    border-color: #40d9ca;
}

.btn-info {
    color: #fff;
    background-color: #48b0f7;
    border-color: #48b0f7;
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info:active:focus {
    color: #fff;
    background-color: #6dc0f9;
    border-color: #6dc0f9;
}

.btn-outline-primary {
    border: 1px solid #E5E4E2;
    background: none;
    white-space: nowrap;
    background: white;

    &.dropdown-toggle {
        display: table;
        width: 100%;
        text-decoration: none;
        color: inherit;
        box-shadow: none;
        border: 1px solid #E5E4E2;
        width: 100%;
        text-align: left;
        margin-right: 15px;
        padding: 8px 10px;
        color: #626262;

        &:hover, &:active, &:focus, &:active:focus {
            color: #626262;
        }

        &:focus-visible {
            outline: none;
        }

        &::after {
            position: absolute;
            right: 12px;
            bottom: 15px;
        }
    }

    &.dropdown-toggle.show {
        color: #626262;
        background-color: inherit;
        border: 1px solid #62626240;

        &:focus-visible {
            outline: none;
        }
    }
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary:active:focus {
    border: 1px solid #62626240;
    background-color: #f0f0f0;
    box-shadow: none;
    color: inherit;

    &.dropdown-toggle.show {
        text-decoration: none;
        color: #626262;
        box-shadow: none;
        border: 1px solid #62626240;
        background-color: #f0f0f0;

        &:focus-visible {
            outline: none;
        }
    }
}

.btn-primary {
    color: white;
    background-color: #48b0f7;
    border-color: #48b0f7;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:active:focus {
    color: white;
    background-color: #6dc0f9;
    border-color: #6dc0f9;
    box-shadow: none;
}

.btn-primary:disabled {
    color: white;
    background-color: #48b0f7;
    border-color: #48b0f7;
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover, .btn-danger:active, .btn-danger:focus, .btn-danger:active:focus {
    color: #fff;
    background-color: #c64643;
    border-color: #c64643;
    box-shadow: none;
}

.btn-text-danger {
    color: #dc3545;
    background-color: #fff;
    border-color: transparent;
}

.btn-text-danger:hover, .btn-text-danger:active, .btn-text-danger:focus, .btn-text-danger:active:focus {
    color: #c64643;
    background-color: #fff;
    border-color: transparent;
    box-shadow: none;
}

.btn-complete {
    width: 100%;
}

a > .label {
    color: inherit;
}

.label {
    font-size: 13px;
    padding: 3px 9px;
    border-radius: 5px;
    font-weight: bold;
    display: inline-block;
    color: #626262;
    background-color: #e6e6e6;

    &.skinny {
        background: transparent;
    }

    &.full {
        width: 100%;
        text-align: center;
    }

    &.paid {
        background-color: #10cfbd;
        color: white;
    }

    &.partlypaid {
        background-color: #FAB033;
        color: white;
    }

    &.dropdown {
        padding: 0px;
        font-size: 11px;
        background-color: transparent;
        text-transform: uppercase;
        width: 100%;
        text-align: left;
        display: block;
    }
}
.label-warning {
    background-color: #FAB033!important;
    color: white;
}
.label-success {
    background-color:#10cfbd;
    color: white;
}
.label-danger {
    background-color: #f55753!important;
    color: white;
}
.label-info {
    background-color: #48b0f7;
    color: white!important;
}

.checkbox {
    padding-left: 0px;

    &.tall {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
.checkbox label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px !important;
    margin-right: 0px;
    font-size: 13px;
    min-height: 20px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    transition: border 0.2s linear 0s, color 0.2s linear 0s;
    white-space: nowrap;
    color: #626262;
}

.checkbox input[type=checkbox] {
    opacity: 0;
    width: 0;
    height: 0;
}

.checkbox label:before {
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 10px;
    position: absolute;
    left: 1px;
    background-color: #fff;
    border: 1px solid #d0d0d0;
    top: 1px;
    border-radius: 3px;
    transition: border 0.2s linear 0s, color 0.2s linear 0s;
}

.checkbox label::after {
    display: inline-block;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 5px;
    top: 2px;
    font-size: 11px;
    transition: border 0.2s linear 0s, color 0.2s linear 0s;
    border-radius: 3px;
}
.checkbox input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}
.checkbox.checkbox-circle label:after {
  border-radius: 99px;
}
.checkbox.checkbox-circle label:before {
  border-radius: 99px;
}
.checkbox input[type=checkbox]:checked + label:before {
  border-width: 8.5px;
}
.checkbox input[type=checkbox]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\F00C";
  color: #fff;
}
.checkbox input[type="checkbox"]:focus + label {
  color: #2c2c2c;
}
.checkbox input[type=checkbox][disabled] + label {
  opacity: 0.65;
}
.checkbox input[type=checkbox][disabled] + label:before {
  background-color: #eceff3;
}
.checkbox.right label {
  margin-right: 35px;
  padding-left: 0 !important;
}
.checkbox.right label:before {
  right: -35px;
  left: auto;
}
.checkbox.right input[type=checkbox]:checked + label {
  position: relative;
}
.checkbox.right input[type=checkbox]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\F00C";
  position: absolute;
  right: -27px;
  left: auto;
}
body.rtl .checkbox label::after {
  left: -1.2px;
}
.checkbox.check-success input[type=checkbox]:checked + label:before {
  border-color: #10cfbd;
}
.checkbox.check-primary input[type=checkbox]:checked + label:before {
  border-color: #6d5cae;
}
.checkbox.check-complete input[type=checkbox]:checked + label:before {
  border-color: #48b0f7;
}
.checkbox.check-warning input[type=checkbox]:checked + label:before {
  border-color: #f8d053;
}
.checkbox.check-danger input[type=checkbox]:checked + label:before {
  border-color: #f55753;
}
.checkbox.check-info input[type=checkbox]:checked + label:before {
  border-color: #3b4752;
}
.checkbox.check-success input[type=checkbox]:checked + label::after,
.checkbox.check-primary input[type=checkbox]:checked + label::after,
.checkbox.check-complete input[type=checkbox]:checked + label::after,
.checkbox.check-warning input[type=checkbox]:checked + label::after,
.checkbox.check-danger input[type=checkbox]:checked + label::after,
.checkbox.check-info input[type=checkbox]:checked + label::after {
  color: #fff;
}
.ui-sortable-helper {
    display: table;
}

.ui-datepicker {
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
    z-index: 1056 !important;
}

.ui-datepicker td {
    padding: 1px 0;
}

.ui-datepicker-header {
    background: transparent;
    border: 0px;
}

.ui-widget-content .ui-state-default {
    border: 0px;
    text-align: center;
    border-radius: 3px;
    background: none;
}

.ui-datepicker-today {
    .ui-state-highlight {
        background-color: #f9eec2;
        border-color: #f9eec2;
        color: #626262;
    }
}

.ui-datepicker-current-day {
    .ui-state-default {
        background-color: #48B0F7;
        color: white;
    }
}

.ui-datepicker-start-date {
    .ui-state-default {
        background-color: #48B0F7;
        border-color: #48B0F7;
        color: white;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}

.ui-datepicker-end-date {
    .ui-state-default {
        background-color: #999999;
        border-color: #999999;
        color: white;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }
}

.ui-datepicker-mid-date {
    .ui-state-default {
        background-color: #e6e6e6;
        border-color: #e6e6e6;
        color: #626262;
        border-radius: 0px;
    }
}

.quickview {
    position: absolute;
    width: 324px;
    height: 100%;
    background: white;
    z-index: 1003;
    top: 0;
    right: 0;
    margin-right: -324px;
    transition-duration: 300ms;
    transition-property: margin-right;
    border-left: 1px solid #e6e6e6;
    overflow-y: scroll;

    &.show {
        margin-right: 0px;
        transition-duration: 300ms;
        transition-property: margin-right;
    }
}

.quickview-body {
    .details {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .subtext {
            font-size: 11px;
            font-style: italic;
        }
    }

    .spinner-border {
        margin: 20px;
    }
}

.spinner-wrapper {
    padding: 20px;
}

.footer {
  color: #ccc;
  font-size: 15px;

  a {
    color: #ccc;
    text-decoration: none;
  }

  p {
    padding: 20px 0px;
  }
}

.jodit-status-bar-link {
    display: none;
}

.creatable {
    div[class*="-menu"] {
        display: none;
    }
}

.section {
    background: #fafafa;
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    margin: 10px 0;

    .section-title {
        font-weight: bold;
    }
}

.class-form-field {
    margin: 10px 0;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    padding: 5px;
    background: #fafafa;
    box-shadow: -1px 1px #62626220;

    .class-form-field-title {
        font-weight: bold;
    }

    p {
        margin: 0px !important;
    }
}

.notification {
    position: absolute;
    z-index: 9;
    font-size: 13px;
    top: 60px;
    left: 72px;
    width: 100%;
    font-weight: bold;
    -webkit-transition-property: top;
    -webkit-transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-in-out;
    transition: top 0.2s ease-in-out;

    .alert {
        margin: 0px;
        padding: 11px;
        background: #fef6dd;
        color: #957d32;
        font-weight: bold;
        border-radius: 0;
    }

    &.ashow {
        top: 60px;
    }

    &.ahide {
        top: 0px;
    }
}

.notification-model-wrapper {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
    overflow: hidden;
    height: 100px;
    display: none;
}

.notification-modal {
    position: absolute;
    z-index: 9;
    top: -50px;
    left: 0px;
    border-radius: 0;
    width: 100%;
    -webkit-transition-property: top;
    -webkit-transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-in-out;
    transition: top 0.2s ease-in-out;

    .alert {
        margin: 0px;
        background: #fef6dd;
        color: #957d32;
        border-radius: 0;
        font-weight: bold;
    }

    &.ashow {
        top: 0px;
    }

    &.ahide {
        top: -55px;
    }
}

.tab-header ~ .modal-body {
    .notification-modal.ashow {
        top: 0px;
    }

    .notification-modal.ahide {
        top: -55px;
    }
}

.simple-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn-group {
    .btn {
        margin: 0px;
    }

    .dropdown {
        margin-right: 0px;
        padding-left: 0px;
    }

    .simple-label {
        margin-right: 15px;
    }
}

.btn-toolbar {
    flex-wrap: nowrap;
    white-space: nowrap;
    display: block;
}

.tooltip {
    top: 0;
}

.alert-dismissible .btn-close {
    padding: 10px;
    font-size: 10px;
}

.popover-body {
    .actions {
        margin-top: 10px;
        margin-bottom: 0px;
        text-align: end;
    }
}

.overdue {
    color: #da4f49;
}

.flex-button-group {
    display: flex;
}

.calendar-outer {
    flex-grow: 1;
}

.header-clock {
    font-size: 30px;
    font-weight: bold;
}

.header-clock-subtitle {
    font-size: 12px;
    text-align: end;
    margin-top: -5px;
}

.venue-preview {
    border: 1px solid #dddddd;
    margin-bottom: 15px;

    .header-bar {
        min-height: 50px;
        padding: 10px 20px 7px 20px;
        background: #48B0F7;
        text-align: center;
        text-transform: uppercase;
        font-size: 25px;
        font-weight: bold;
        color: white;
        vertical-align: middle;
        line-height: normal;
        letter-spacing: 0.02em;
    }

    table {
        margin-bottom: 0px;
    }

    table th {
        white-space: normal;
    }

    table th.dark {
        background: #e6e6e6;
    }

    table td.bleft {
        border-left: 1px solid #e6e6e6;
    }

    table td.bright {
        border-right: 1px solid #e6e6e6;
    }

    table td {
        font-size: 16px;
        font-weight: bold;
    }
}

.calendar-toolbar {
    text-align: center;

    .dropdown {
        margin-left: 0px;
    }

    .dropdown-menu.single {
        padding: 0px;

        .dropdown-item {
            padding: 10px;
        }
    }

    .btn-group .btn {
        padding-left: 10px;
        padding-right: 10px;
    }

    .ui-datepicker {
        position: absolute;
    }

    .calendar-title {
        justify-content: center;
    }

    .calendar-title-content {
        border-radius: 5px;
    }

    .title {
        font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 20px;
        line-height: 31px;
        display: inline-block;
        color: #626262 !important;
        margin: 0px;
        padding-top: 5px;
        font-weight: 300;
    }

    .calendar-icon {
        font-size: 2em;
    }
}

.fc {
    height: 100%;

    .fc-view-harness {
        height: inherit !important;
    }

    .fc-col-header-cell a {
        color: #626262;
    }

    .fc-timegrid-event.fc-event {
        border-radius: 0px;
        opacity: 0.95;
        border-width: 0 0 0 3px !important;

        &:hover {
            opacity: 1;
            min-height: 50px;

            &::after {
                content: "=";
                position: absolute;
                bottom: -5px;
                left: 50%;
                transform: translate(-50%, 0);
                font-size: 15px;
                color: #3d3d3d;
            }
        }
    }

    .fc-event-main {
        background-color: #ffffff30;
        font-weight: bold;
        color: #3d3d3d;
    }

    .fc-event-time {
        background-color: #ffffff30;
        padding: 3px;
        width: 100%;

        display: flex;
        flex-direction: row;
        vertical-align: middle;

        .fa {
            opacity: 0.7;
            font-size: 12px;
            margin-left: 3px;

            &.fa-hover:hover {
                opacity: 1;
            }
        }
    }

    .fc-event-time-start {
        flex-grow: 0;
        flex-shrink: 0;
        max-width: 100%;
        padding-right: 10px;

        .caption {
            font-size: 8px;
            color: #555555;
            padding-left: 3px;
        }
    }

    .fc-event-time-end {
        flex-grow: 1;
        flex-shrink: 0;
        min-width: 0px;
        text-align: end;
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .fc-event-title-description {
        display: flex;
        flex-direction: column;
        gap: 2px;
    }

    .fc-timeline-event-harness, .fc-daygrid-event-harness {
        .fc-event-time-end {
            flex-grow: 0;
        }

        .fc-event-time {
            width: inherit;
        }

        .fc-event-title-description {
            flex-direction: row;
        }

        .bPad {
          background:none !important;
        }
    }

    .fc-event-title-container {
        padding-left: 3px;

        .fc-event-title {
            white-space: pre;
            line-height: 12px;
        }
    }

    .fc-event-main-frame {
        background: repeating-linear-gradient(45deg,rgba(255,255,255,.4),rgba(255,255,255,.4) 5px,rgba(255,255,255,.1) 5px,rgba(255,255,255,.1) 10px) bottom no-repeat;
        background-size: 100% 0px;

        &.bPad.bPad-0, &.bPad.bPad-1, &.bPad.bPad-null,
        &.bPad.bPad5-0, &.bPad.bPad5-1, &.bPad.bPad5-null,
        &.bPad.bPad10-0, &.bPad.bPad10-1, &.bPad.bPad10-null,
        &.bPad.bPad15-0, &.bPad.bPad15-1, &.bPad.bPad15-null,
        &.bPad.bPad20-0, &.bPad.bPad20-1, &.bPad.bPad20-null,
        &.bPad.bPad30-0, &.bPad.bPad30-1, &.bPad.bPad30-null,
        &.bPad.bPad60-0, &.bPad.bPad60-1, &.bPad.bPad60-null
        {
          background-size:100% 0px;
        }

        /* For calendarInterval 5 */
        &.bPad.bPad5-5 {
          background-size:100% 20.5px;
        }

        &.bPad.bPad5-10 {
          background-size:100% 41px;
        }

        &.bPad.bPad5-15 {
          background-size:100% 61.5px;
        }

        &.bPad.bPad5-20 {
          background-size:100% 82px;
        }

        &.bPad.bPad5-30 {
          background-size:100% 123px;
        }

        &.bPad.bPad5-60 {
          background-size:100% 246px;
        }

        /* For calendarInterval 10 */
        &.bPad.bPad10-5 {
          background-size:100% 10.25px;
        }

        &.bPad.bPad10-10 {
          background-size:100% 20.5px;
        }

        &.bPad.bPad10-15 {
          background-size:100% 30.75px;
        }

        &.bPad.bPad10-20 {
          background-size:100% 41px;
        }

        &.bPad.bPad10-30 {
          background-size:100% 61.5px;
        }

        &.bPad.bPad10-60 {
          background-size:100% 123px;
        }

        /* For calendarInterval 15 */
        &.bPad.bPad15-5 {
          background-size:100% 6.8333px;
        }

        &.bPad.bPad15-10 {
          background-size:100% 13.66px;
        }

        &.bPad.bPad15-15 {
          background-size:100% 20.5px;
        }

        &.bPad.bPad15-20 {
          background-size:100% 27.3333px;
        }

        &.bPad.bPad15-30 {
          background-size:100% 41px;
        }

        &.bPad.bPad15-60 {
          background-size:100% 82px;
        }

        /* For calendarInterval 30 */
        &.bPad.bPad30-5 {
          background-size:100% 3.4166px;
        }

        &.bPad.bPad30-10 {
          background-size:100% 6.8333px;
        }

        &.bPad.bPad30-15 {
          background-size:100% 10.25px;
        }

        &.bPad.bPad30-20 {
          background-size:100% 13.6666px;
        }

        &.bPad.bPad30-30 {
          background-size:100% 20.5px;
        }

        &.bPad.bPad30-60 {
          background-size:100% 41px;
        }
    }

    .fc-timegrid-event-short {
        .fc-event-main-frame {
            flex-direction: column;
        }

        .fc-event-time:after {
            content: "";
        }

        .fc-event-title {
            font-size: 12px;
        }
    }

    .fc-margin {
        .fc-event-time {
            display: none;
        }

        &:hover {
            min-height: 41px;
            z-index: 10000 !important;
            .fc-event-time, .fc-event-title {
                display: inherit;
            }
        }
    }

    .fc-timegrid-event-harness {
        &:hover {
            z-index: 10000 !important;
            left: 0 !important;
            right: 0 !important;
        }
    }

    .fc-event-mirror {
        .fc-event-time {
            color: #3d3d3d;
        }
        .fc-event-main {
            background: repeating-linear-gradient(45deg,rgba(255,255,255,.4),rgba(255,255,255,.4) 5px,rgba(255,255,255,.1) 5px,rgba(255,255,255,.1) 10px) bottom no-repeat
        }
    }

    .fc-timegrid-col {
        .fc-non-business {
            background: repeating-linear-gradient(45deg,rgba(255,255,255,.4),rgba(255,255,255,.4) 5px,rgba(255,255,255,.1) 5px,rgba(255,255,255,.1) 10px) bottom no-repeat;
            background-color: rgb(221, 221, 221);
            opacity: 0.3;
        }
    }
}

ol.sortable {
    list-style-type: none;
    padding-left: 0px;

    .group-header {
        font-size: 16px;
        font-weight: bold;
    }

    .list-header {
        background: #f5f5f5 none repeat scroll 0 0;
        border: 1px solid #eee;
        border-radius: 3px;
        margin: 0;
        cursor: move;
        padding: 6px 16px 6px 16px;
        vertical-align: middle;
    }

    ol.sortable-group {
        list-style-type: none;
        min-height: 10px;
        padding-left: 0px;
        margin-left: 32px;
    }

    ol.sortable-group:empty {
        height: 40px;
        border: 1px dashed #626262;
        margin-top: 5px;
        border-radius: 3px;
    }

    ol.sortable-group:empty:after {
        content: attr(data-content);
        position: relative;
        left: 10px;
        top: 6px;
    }

    li {
        margin-top: 5px;
        line-height: 24px;
    }
}

.invoice-logo {
    max-height: 150px;
    max-width: 200px;
}

.booking-popover {
    width: 300px !important;
    max-width: 300px !important;
    font-size: 12px;

    .popover-body {
        color: #626262;
        .btn {
            font-size: 13px;
        }

        .full-row {
            display: inline-grid;
            padding: 0px;
            margin-top: 10px;

            &.skinny {
                margin-top: 0px;
            }
        }

        .row > [class*='col-'] {
            padding-right: 0px;
        }

        .btn-sm {
            padding: 0px 4px;
        }

        .popover-title {
            font-size: 14px;
            font-weight: bold !important;
            color: #3a8fc8;

            h6 {
                margin: 0;
                display: inline;
                font-size: 15px;
            }

            .group-title {
                cursor: pointer;
            }

            .subheader {
                color: #10cfbd;
                font-size: 13px;
            }
        }

        .popover-subtitle {
            font-size: 13px;
            font-weight: bold;
            color: #10cfbd;
        }
    }

    .dropdown {
        padding-left: 0px;
    }
}

.spacing {
    height: 8px;
}

.single-select-flat {
    color: #626262;
    background: #fafafa;
    display: table-cell;
    vertical-align: top;
    text-align: center;

    &.disabled {
        pointer-events: none;
    }

    div {
        padding: 0 10px;
        background: none;
        border-bottom: 1px solid transparent;
        font-size: 12px;
        font-weight: bold;
        line-height: 18px;

        &:hover, &.selected {
            background-color: #10cfbd;
            color: white;
            cursor: pointer;
        }
    }
}

.booking-date-wrapper {
    background: #ffffff40;
    color: white;
    padding: 5px 12px;
    border-radius: 4px;
    white-space: nowrap;
    pointer: cursor;

    &:hover {
        background: #ffffff60;
    }
}

.pop-date {
    color: white;
    border-radius: 4px;
    white-space: nowrap;
    pointer: cursor;

    .ui-datepicker {
        position: relative;
    }

    &:hover {
        background: #ffffff60;
    }
}

.select-container {
    padding: 0px;

    .select2__control {
        border: 0px;

        &.select2__control--is-focused {
            box-shadow: none;
        }
    }

    .select2__menu {
        z-index: 100;
        margin: 0;
    }

    .select2__multi-value {
        background-color: #10cfbd !important;

        .select2__multi-value__label {
            color: white;
            font-weight: bold;
        }

        .select2__multi-value__remove {
            color: #626262;
            opacity: 0.5;
        }

        .select2__multi-value__remove:hover {
            background-color: inherit;
            opacity: 1;
        }
    }

    .select2__menu-list {
        background: #fafafa;

        .subtitle {
            font-size: 12px;
            color: #626262;
        }

        .title {
            font-weight: 400;
            color: #262626;
        }
    }

    .select2__multi-value__label {
        .subtitle {
            display: none;
        }
    }

    .select2__option:hover, .select2__option--is-focused, .select2__option--is-selected {
        background: #f5f5f5;
    }
}

.tab-header {
    display: flex;
    position: relative;
    z-index: 8;

    .left {
        flex-grow: 1;
    }

    .right {
        flex-basis: max-content;
    }
}

.pac-container {
    z-index: 1056;
}

.infinite-scroll-component {
    min-height: 350px;
}

.repeat-info {
    padding: 17px;
    text-align: center;
    color: #626262;
    border: 1px solid #E5E4E2;
    font-weight: bold;
    margin-bottom: 10px;
}

.paired-venues {
    margin-bottom: 10px;
}

.StripeElement {
    background: transparent;
    border-radius: 5px;
    padding: 10px;
}

.cc_card_input {
    background: #fafafa;
    padding: 10px;

    .StripeElement {
        padding: 10px;
        margin-top: 5px;
    }
}

.cc_card_payment_intent {
    .StripeElement {
        min-height: 140px;
    }
}

.booking-actions {
    background: #fafafa;
    padding: 15px;

    .cc-input {
        .btn {
            padding: 0px;
        }
        .fa {
            font-size: 30px;
        }
    }

    .unselected {
        opacity: 0.5;
    }
    .selected {
        opacity: 1;
    }
}

.booking-success {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    margin-bottom: 25px;
}

.class-wrapper {
    background: white;
    border: 1px solid #e2e2e2;
    padding: 20px;
}

.class-hero-image {
    max-width: 100%;
    min-height: 150px;
    max-height: 250px;

    img {
        max-width: 100%;
        object-fit: cover;
    }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 576px) {
  .content_wrapper, .calendar-wrapper {
    padding-left: 0px !important;
  }

  .content_wrapper {
    padding-top: 50px !important;
  }

  .container-fluid {
    padding-right: 0px;
    padding-left: 0px;
  }

  .pull-left, .pull-right, .pull-center {
    height: 50px;
    padding: 0px;
  }

  .header {
    height: 50px;
    padding-left: 5px;
  }

  .header-item {
    height: 50px;
  }

  .notification {
    left: 0px;

    &.ashow {
        top: 50px;
    }

    &.ahide {
        top: 0px;
    }
  }

  .content-box {
    .content-body {
        padding: 20px 10px;

        &.invoice-container {
            padding: 25px;
        }
    }
  }

  .skinny-md {
    margin-right: 0px !important;

    &::after {
        border: 0px !important;
    }
  }

  .hide-md {
    display: none;
  }

  .calendar-toolbar {
    text-align: start;

    .calendar-title {
        justify-content: start;
    }

    .calendar-title-content {
        border: 1px solid rgba(0, 0, 0, 0.12);
        padding: 1px 5px 0px 5px;
    }
    .title {
        font-size: 16px !important;
        padding-top: 1px !important;
        padding: 1px 5px 5px 0px;
    }

    .calendar-icon {
        font-size: 16px;
    }
  }

  .dropdown {
    padding-left: 0px;
  }
}

.payment-method-group {
    margin-bottom: 30px;
}

.agreement-payment-advice {
    td {
        padding-bottom: 0px;

        &.lined {
            border-bottom: 1px solid #aeaeae;
        }
    }
}

@page {
    size: auto;
    margin: 0mm;
}

@media print {
    .noprint {
        display: none !important;
    }

    .showprint {
        display: inherit;
    }

    .content_wrapper, .content-container {
        padding: 0px;
        overflow: visible;
    }

    .content-box {
        border: 0px;
    }

    .calendar-outer {
        width: 100%;
    }

    .fc .fc-view-harness {
        width: 100%;
        height: 2000px !important;
    }

    .invoice-container {
        padding: 0px;
    }
}

