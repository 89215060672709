
.badge {
    font-size: 13px;
}

p.thin {
    margin: 0px;
}

.classes-container {
    padding: 10px 0 70px;
}

.descriptionTable {
    td {
        border-bottom: 1px solid #ddd;
        padding: 8px;
        font-size: 14px
        
    }

    i {
        padding-left: 10px; 
        font-size: 16px;
    }

    i:hover {
        cursor: pointer;
    }
    
}

.classes-info {
    background: #FBF8E3;
    margin-top: 20px;
    color: #333;

    .info-title {
        color: #333;
    }
}

.table1 {
    text-align: left;
    font-size: 14px;

    td {
        vertical-align: middle;
    }

    .btn-primary {
        font-size: 14px;
    }
}
