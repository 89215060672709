
.table-wrapper {
    display: inline-block;
    overflow-x: scroll;
    width: 100%;
}

.table {
    color: #626262;
    font-size: 13.5px;
    --bs-table-hover-bg: transparent;

    tbody {
        border: 0px;
    }

    th {
        color: #2c2c2c60;
        text-transform: uppercase;
        white-space: nowrap;
        vertical-align: middle;

        &.controls {
            text-transform: inherit;
            font-weight: normal;
            vertical-align: top;

            form {
                padding-left: 0px;
            }

            input {
                margin-top: 5px;
                font-size: 13px;
                color: #626262;
            }

            .btn.dropdown-toggle {
                width: 100%;
                margin-top: 5px;
            }
        }

        &.no-stretch {
            white-space:nowrap;
            width: 1px;
        }

        &.controls>.form-control {
            margin-top: 7px;
        }

        &.controls>.checkbox {
            margin-top: 10px;
        }

        &.controls>.dropdown {
            padding-left: 0px;
        }

        &.wrappable {
            white-space: break-spaces;
        }
    }

    td {
        border-color: #e2e2e2;
        vertical-align: middle;

        &.skinny {
            padding: 0px;
        }

        &.no-stretch {
            white-space:nowrap;
            width: 1px;
        }
    }

    tr {

        &.skinny {
            td {
                padding: 0px;
            }
        }

        &.active {
            background-color: #e2e2e260;
        }

        &.danger {
            background: #FFEEEE;
        }
    }

    tbody {
        border: 0px;
    }

    &>:not(:first-child) {
        border: 0px;
    }

    tbody tr td {
        border-top: 0px;
    }

    tr .dropdown {
        width: 100%;
    }

    &.skinny {
        margin-bottom: 0px;
    }

    .upline {
        border-top: 1px solid #e2e2e280;
    }
}

.table-hover>tbody>tr:hover>* {
    background-color: #daeffe;
    color: #626262;
}

.de-highlighted {
    opacity: 0.5;
}