
.modal {
    border: none;
    box-shadow: none;
}

.modal-content {
    margin-top: 70px;
    margin-bottom: 30px;
}

.modal-md {
    width: 600px;
    max-width: 600px;
}

.modal-lg {
    width: 900px;
    max-width: 900px;
}

.modal-dialog {
    margin-top: 0px;
    margin-bottom: 0px;
}

.modal-content1 {
    border: none;
    background: #fafafa;
    color: #626262;
}

.modal-header {
    background-color: var(--header-color);
    color: #ffffffe0;
    z-index: 8;
    padding: 14px 25px;
    text-transform: uppercase;
    border: none;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    position: relative;

    .modal-title {
        font-size: 18px;

        i {
            margin-bottom: 3px;
        }
    }

    .btn-close {
        font-size: 19px;
        color: white;
        padding: 0px;
        display: grid;

        .fa {
            font-size: 24px;
            vertical-align: middle;
        }
    }
}

.modal-body {
    padding: 25px 25px 10px;
    font-size: 14px;
    z-index: 10;

    &.packed {
        padding: 0px;
        border-bottom: 1px solid #E5E4E2;
    }
}

.modal-footer {
    background: #fafafa;
    padding: 10px;
}

@media (max-width: 576px) {
    .modal-md {
        width: 100%;
        max-width: 100%;
        margin: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .modal-lg {
        width: 100%;
        max-width: 100%;
        margin: 0px;
        padding-right: 0px;
    }
}

@media (max-width: 1200px) {
    .modal-lg {
        width: 100%;
        max-width: 900px;
        margin: 0px auto;
        padding-right: 0px;
    }
}
