/* Form Groups
--------------------------------------------------
*/

.form-label {
    display:block;
    text-transform: uppercase;
    color: #626262;
    font-size: 11px;
    font-weight: 600;
    margin: 0px;
  }

.form-check-input {
    border: 1px solid #e6e6e6;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0px;
}

button[type="submit"] {
    .spinner-border {
        display: none;
        margin: 0 auto;
    }
    .spinner-border-sm {
        height: 20px;
        width: 20px;
    }
    .submit-button-content {
        display: inherit;
    }
}
button[type="submit"].loading {
    .spinner-border {
        display: inherit;
        margin: 0 auto;
    }
    .submit-button-content {
        display: none;
    }
}

.form-group, .input-group {
  position: relative;
  margin-bottom: 10px;
  background-color: white;
  border: 1px solid #E5E4E2;
  border-radius: 2px;
  padding: 7px 7px 4px 12px;
  transition: background-color 0.2s ease;
  display: table;
  width: 100%;

  &.tight {
    display: flex !important;
    width: 100%;

    .dropdown {
        max-width: 60px;
        z-index: unset;
        flex: 1;
    }

    .form-label {
        overflow: hidden;
        text-overflow: ellipsis;
    }
  }

  &.borderless {
    border: 0px;
  }

  &.input-group {
    padding: 0;
    display: table;

    .form-label, .form-select {
        padding-left: 12px;
        line-height: 15px;
        min-height: 15px;

        &.inline {
            margin-top: 20px;
        }
    }

    .form-label {
        margin-top: 8px;
    }

    .form-subtext {
      padding-left: 12px;
      color: #212529;
      margin-top: -5px;
    }

    .simple-label {
        padding-left: 12px;
        margin-top: 5px;
        margin-bottom: 7px;
        line-height: 21px;
        min-height: 21px;
    }

    .form-select {
        margin-top: 2px;
        background-position: right 8px center;
    }

    .form-switch {
        padding-right: 7px;
        padding-top: 7px;
        padding-bottom: 7px;
    }

    input, textarea {
        padding-left: 12px;
        margin-left: 0px !important;
        margin-bottom: 3px;

        &.inline {
            margin-top: 4px;
        }
    }

    input[type="file"] {
        width: 100%;
        line-height: 14px;
        margin-top: 3px;
        margin-bottom: 10px;
    }

    .form-control, .form-select {
        width: inherit;
        background: none;

        &.borderless {
            border: 0px;
        }
    }

    .dropdown-toggle {
        padding: 0px;
        border: 0px;

        &:hover, &:active, &:focus, &:active:focus {
            color: #626262;
            border: 0px;
        }

        &:focus-visible {
            outline: none;
            border: 0px;
        }
    }

    &.inline {
        margin-bottom: 0px;

        .form-control {
            margin-top: 3px;
        }

        .input-group-text {
            height: 35px;
        }

        .dropdown {
            // padding-top: 3px;
        }
    }

    textarea {
        padding-top: 5px;
    }
  }

  .dropdown {
    padding-left: 0px;
  }

  .dropdown-toggle {
    height: 100%;
    border-radius: 0px;
  }

  &.disabled {
    background-color: #e9ecef;
    opacity: 0.65;
  }

  .input-group-text {
    background-color: #fafafa;
    width: 1%;
    white-space: nowrap;
  }

  .input-group-text, .input-group-addon, .form-switch, .form-color {
    display: table-cell;
    height: 53px;
    color: #626262;
    font-size: 14px;
    vertical-align: middle;
    border: 0px;
    text-align: center;
    min-width: 40px;

    &.divider {
        padding: 0px;
        min-width: 1px;
        max-width: 1px;
    }

    &.skinny {
        height: inherit;
    }
  }

  .form-switch {
    padding: 1px;
    width: 80px;
    height: 56px;
    text-align: end;
  }

  .form-color {
    height: 56px !important;
    padding: 5px;
    width: 50px;
  }

  .form-color-value {
    padding: 5px;
    width: 33px;
    height: 33px;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .github-picker {
    position: absolute !important;
    z-index: 10 !important;
    width: 285px !important;

    span {
        margin: 2px !important;
        width: 35px !important;
        height: 35px !important;

        div {
            width: 35px !important;
            height: 35px !important;
        }
    }
  }

  .form-check-input {
    margin: 0px;
    height: 35px;
    width: 55px;
    display: inline-block;
    vertical-align: middle;
    background-size: 45px 45px;
    border: 1px solid #e6e6e6;
    background-position: -5px center;
  }

  .form-check-input:hover, .form-check-input:focus, .form-check-input:active {
    box-shadow: none;
  }

  .form-check-input:checked {
    background-color: rgb(72, 176, 247);
    background-size: 45px 45px;
    border: 0px;
    background-position: 15px center;
  }

  .form-check-input:before {
    width: 20px;
  }

  .form-select {
    border: 0px;
    box-shadow: none;
    padding: 0px 15px 0px 0px;
    font-size: 13px;
    margin-left: 0px !important;
    min-height: 25px;
    text-overflow: ellipsis;
    background-position: right 0px center;
  }

  .form-select:focus, .form-select:active {
    box-shadow: none;
  }

  .form-label {
    display:block;
    text-transform: uppercase;
    color: #626262;
    font-size: 11px;
    font-weight: 600;
    margin: 0px;
  }

  &.inline {
    .form-label {
        margin-top: 12px;
    }
  }

  input {
    border: 0;
  }

  textarea {
    border: 0;
    height: 25px;

    &.large {
        min-height: 100px;
    }
  }

  input::placeholder, textarea::placeholder {
    opacity: .4;
  }

  .form-control {
    padding: 1px 0px;
    font-size: 13px;
    min-height: 30px;
  }

  .form-control:focus {
    border: 0;
    box-shadow: none;
  }

  &.required:after {
    color: #f55753;
    content: "*";
    font-family: arial;
    font-size: 20px;
    position: absolute;
    right: 5px;
    top: 0px;
  }

  &.focused {
    label {
      opacity: 1;
    }
  }

  &.error {
    border-color: #dc212760;
    background: #dc212710;
  }

  .file-preview {
    display: table-cell;
    background-color: #29394F!important;
    height: 53px;
    width: 53px;
    vertical-align: middle;
    padding-left: 3px;
    padding-right: 3px;
    text-align: center;

    img {
        max-width: 100%;
        max-height: 100%;
    }
  }

  .file-clear {
    display: table-cell;
    height: 53px;
    width: 23px;
    vertical-align: middle;
    padding-left: 3px;
    padding-right: 3px;
    text-align: center;
  }
}

.form-control {
    box-shadow: none;
    border-color: #E5E4E2;
    background: none;
}

.form-control:focus {
    box-shadow: none;
    border-color: #62626240;
}

input.form-control {
    font-size: 14px;
}

.form-error-message {
    margin-bottom: 5px;
    margin-top: -10px !important;
    font-style: italic;
    color: #dc2127;
    padding-left: 3px;
    font-size: 12px;
    min-height: 5px;
}

.form-group.inline + .form-error-message {
    margin-top: 0px !important;
}

.form-switch .form-check-input, .form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8' width='150' height='150' version='1.1'%3E%3Ccircle r='3' stroke='%2362626270' stroke-width='0.1' fill='rgba(256, 256, 256, 1)'/%3E%3C/svg%3E%0A");
}

.form-check-input:checked {
    background-color: #48b0f7;
    border-color: #48b0f7;
}

.form-check-inline {
    margin-right: 0px;
}

.form-check {
    padding-top: 1px;
}

.lg-switch {
    input[type="checkbox"] {
        width: 50px;
        height: 30px;
    }
}
