
.partner-facility-item-wrapper {
    padding: 10px 20px 10px 0px !important;
}

.partner-facility-item {
    border: 1px solid rgba(0, 0, 0, 0.10);
    border-radius: 4px;
    padding: 10px;
    background: #f0f0f0;

    .partner-facility-item-header {
        font-size: 17px;
        font-weight: bold;
    }

    .partner-facility-item-body {
        min-height: 50px;
    }

    .partner-facility-item-footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }
}

.partner-facility-item-new {
    border: 1px dashed rgba(0, 0, 0, 0.30);
    border-radius: 4px;
    padding: 10px;
    min-height: 135px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
